<template>
  <v-container
    class="materials-container rounded elevation-3"
    fluid>
    <v-row
      justify="space-between"
      align="center">
      <v-col cols="6">
        <h2>Materials</h2>
      </v-col>
      <v-col cols="6">
        <div class="d-flex flex-row justify-end align-base-line">
          <search-box
            v-model="filters.search"
            :disabled="loading"
            @on-search="fetchMaterials(true)" />
          <v-btn
            class="ml-2"
            color="secondary"
            outlined
            :loading="loading"
            @click="showForm()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          class="mt-5 fabric-table"
          :headers="materailsHeaders"
          :items="materials"
          :items-per-page="-1"
          dense
          :loading="loading"
          hide-default-footer>
          <template #[`item.status`]="{ item }">
            <v-switch
              v-model="item.status"
              true-value="active"
              false-value="inactive"
              class="ml-4"
              @change="checkStatusInactive($event, item)">
            </v-switch>
          </template>
          <template #[`item.image`]="{ item }">
            <gw-product-image
              v-if="item.imageUrls.length"
              :src="item.imageUrls[0]"
              class="cursor-pointer py-3"
              @click="previewImg(item.imageUrls)" />
          </template>
          <template #[`item.size`]="{ item }">
            {{ item.size || '' }}
          </template>
          <template #[`item.material`]="{ item }">
            {{ item.material || '' }}
          </template>
          <template #[`item.color`]="{ item }">
            {{ item.color || '' }}
          </template>
          <template #[`item.unitPrice`]="{ item }">
            {{ item.unitPrice | showFullPriceFormat() }} {{ item.currency.toUpperCase() }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-menu
              offset-x
              left>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  link
                  @click="editMaterial(item.id)">
                  <v-list-item-title> Edit </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="modalDeleteMaterial(item.id)">
                  <v-list-item-title> Delete </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <div class="text-center mt-5">
          <v-pagination
            v-model="filters.page"
            :length="filters.pages"
            :total-visible="7"
            :disabled="loading" />
        </div>
        <div>
          <v-dialog
            v-model="dialog"
            max-width="500"
            persistent>
            <v-card>
              <v-card-title>
                <h3>หมายเหตุ</h3>
              </v-card-title>
              <v-card-text>
                <v-form ref="statusNote">
                  <v-textarea
                    v-model.trim="dataDialog.statusNote"
                    label="หมายเหตุ"
                    dense
                    hide-details
                    :rules="textBoxRules"
                    outlined />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="closeDialog()">
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="updateStatusMaterial(dataDialog)">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
    <ImgModalCarousel
      v-model="previewEnabled"
      :items="currentImgPreview" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import MaterialsProvider from '@/resources/MaterialsProvider'
import SearchBox from '@/components/SearchBox.vue'
import ImgModalCarousel from '../components/ImgModalCarousel.vue'

const MaterialsService = new MaterialsProvider()

export default {
  components: {
    SearchBox,
    ImgModalCarousel
  },
  data () {
    return {
      loading: false,
      previewEnabled: false,
      currentImgPreview: [],
      materials: [],
      filters: {
        search: '',
        page: 1,
        pages: 1
      },
      materailsHeaders: [
        {
          text: '',
          value: 'status',
          align: 'center',
          sortable: false
        },
        {
          text: 'รูปภาพ',
          value: 'image',
          align: 'center',
          sortable: false
        },
        {
          text: 'ชื่อ',
          value: 'name',
          sortable: false
        },
        {
          text: 'โรงงาน',
          value: 'factory',
          align: 'center',
          sortable: false
        },
        {
          text: 'สี',
          value: 'color',
          align: 'center',
          sortable: false
        },
        {
          text: 'วัสดุ',
          value: 'material',
          align: 'center',
          sortable: false
        },
        {
          text: 'ขนาด',
          value: 'size',
          align: 'center',
          sortable: false
        },
        {
          text: 'ราคาต่อหน่วย',
          value: 'unitPrice',
          align: 'center',
          sortable: false
        },
        {
          text: 'คอลเลคชั่น',
          value: 'collectionName',
          align: 'center',
          sortable: false
        },
        {
          text: 'หมายเหตุ',
          value: 'statusNote',
          align: 'center',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ],
      historyMaterial: [],
      dialog: false,
      dataDialog: {},
      textBoxRules: [
        (v) => !!v || 'Field is require!'
      ]
    }
  },
  watch: {
    'filters.page': {
      handler () {
        this.fetchMaterials()
      },
      deep: true
    }
  },
  created () {
    this.fetchMaterials()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    async fetchMaterials (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.materials = []
          this.filters.page = 1
        }

        const { data } = await MaterialsService.getAll({
          search: this.filters.search,
          page: this.filters.page
        })

        this.filters.pages = data.pages

        this.materials.push(...data.results)
      } catch (error) {
        console.error('fetchMaterials', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.dialog = false
        this.dataDialog = {}
      }
    },
    showForm () {
      this.$router.push({ name: 'NewMaterialForm' })
    },
    editMaterial (id) {
      this.$router.push({ name: 'EditMaterialForm', params: { id } })
    },
    modalDeleteMaterial (id) {
      this.setModal({
        value: true,
        title: 'DeleteMaterial',
        message: 'Do you want to delete this material?',
        confirmText: 'Delete',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.deleteMaterial(id)
      })
    },
    async deleteMaterial (id) {
      try {
        this.loading = true

        await MaterialsService.deleteOne(id)

        this.setSnackbar({
          value: true,
          message: 'Delete material success',
          type: 'success'
        })
        this.fetchMaterials(true)
      } catch (error) {
        console.error('deleteMaterial', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    previewImg (img) {
      this.currentImgPreview = img
      this.previewEnabled = !!this.currentImgPreview.length
    },
    checkStatusInactive (status, data) {
      if (status === 'inactive') {
        this.dialog = true
        this.dataDialog = data
      } else {
        this.updateStatusMaterial(data)
      }
    },
    closeDialog () {
      this.dialog = false
      this.dataDialog = ''
      this.fetchMaterials()
    },
    async updateStatusMaterial (data) {
      try {
        const valid = data.status === 'active' ? true : this.$refs.statusNote.validate()
        if (valid) {
          const res = await MaterialsService.updateOne(data.id, { ...data, statusNote: data.statusNote })
          if (res.message === 'done') {
            this.setSnackbar({
              value: true,
              message: 'Update fabric success',
              type: 'success'
            })
          }
          this.fetchMaterials()
        }
      } catch (error) {
        console.error('updateStatusMaterial', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>
.materials-container {
  background-color: white;
}
.cursor-pointer {
  cursor: pointer;
}
.table-wrap {
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  height: 610px;
  overflow-y: scroll;
}
.detail-wrap {
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  height: 610px;
  overflow-y: scroll;
}

</style>
